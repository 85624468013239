<template>
  <div class="display-box" :style="controlStyle">
    <svg
      v-if="SVGContent"
      width="100%"
      height="100%"
      viewBox="0 0 160 160"
      v-html="SVGContent"
      :style="SVGStyle"
    ></svg>
    <div v-else-if="isLine" :style="lineStyle" />
  </div>
</template>

<script>
import {pick} from "lodash";

export default {
  name: "SynopticShape",
  props: {
    control: Object,
    currentRect: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      hasFocus: false,
      wrapperStylePropeties: [
        "opacity",
        "border",
        "border-radius",
        "background-color",
        "visibility",
        "box-shadow",
        "opacity"
      ]
    };
  },
  computed: {
    mode() {
      //return this.$store.getters['dashboard/mode']||'viewer';
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    shape() {
      // be aware that a shape object might not exist (square is the default)
      return this?.control?.synopticComponent?.shape;
    },
    SVGContent() {
      return this?.shape?.svg_content || null;
    },
    SVGStyle() {
      if (!this.SVGContent) return null;
      let ctlStyle = this?.control?.synopticComponent?.style || {};
      let dropShadow = ctlStyle["box-shadow"];
      if (dropShadow != "none") {
        let lst = dropShadow.split(" "); //['2px', '2px', '10px', '10px', 'rgba(252,60,173,1)', 'inset']
        lst.splice(3, 1); // remove length
        lst.splice(4, 1); // remove inset
        return {
          filter: `drop-shadow(${lst.join(" ")})`
        };
      }
      return null;
    },
    controlStyle() {
      let ctlStyle = this?.control?.synopticComponent?.style || {};
      if (this.SVGContent) {
        let strokeSize = "4px";
        let strokeDashArray = "none";
        let strokeColor = "rgb(85, 85, 85)";
        if (ctlStyle["border"]) {
          let v = this.$utils.trim(ctlStyle["border"]).split(" ");
          strokeSize = v.shift();
          let strokeStyle = v.shift();
          if (strokeStyle == "none") {
            strokeSize = "0px";
          } else if (strokeStyle == "dashed") {
            strokeDashArray = "10, 5";
          } else if (strokeStyle == "dotted") {
            strokeDashArray = "2, 5";
          }
          strokeColor = v.join(" ");
        }
        return {
          transform: `rotate(${parseInt(
            this.control.synopticComponent.rotation || 0
          )}deg)`,
          width: this.currentRect.width + "px",
          height: this.currentRect.height + "px",
          opacity: ctlStyle["opacity"] ?? 1,

          "stroke-dasharray": strokeDashArray,
          "--stroke": strokeSize,
          "--stroke-color": strokeColor,
          "--fill-color": ctlStyle["background-color"]
        };
      } else {
        let style = {
          transform: `rotate(${parseInt(
            this.control.synopticComponent.rotation || 0
          )}deg)`,
          width: this.currentRect.width + "px",
          height: this.currentRect.height + "px",
          "border-radius": ctlStyle["border-radius"] ?? "3px",
          ...pick(ctlStyle, this.wrapperStylePropeties),
          ...pick(this.tmp?.style, this.wrapperStylePropeties)
        };
        if (this.isLine) {
          style = structuredClone(style);
          delete style.border;
          delete style["box-shadow"];
        }
        return style;
      }
    },
    isLine() {
      return this.shape && this.shape.id == "line";
    },
    lineStyle() {
      if (!this.isLine) return null;
      let ctlStyle = this?.control?.synopticComponent?.style || {};
      let height = "auto";
      if (ctlStyle["border"]) {
        let v = this.$utils.trim(ctlStyle["border"]).split(" ");
        height = v.shift();
      }
      return {
        width: "100%",
        height: height,
        borderTop: ctlStyle["border"],
        boxShadow: ctlStyle["box-shadow"] || "none"
      };
    }
  },
  methods: {},
  mounted: function() {
    this.$emit("hasContent", true);
    // console.log(JSON.stringify(this.controlStyle));
  }
};
</script>

<style scoped>
.display-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  /* line-break must be explicit declared in value control property due IOS text adjusts (you might make use of 0x10 line-break)  */
  white-space: normal;
  -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust);
}
</style>
