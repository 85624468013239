<template>
  <img
    class="synoptic-image"
    crossorigin="anonymous"
    v-if="printout"
    :src="src"
    :style="style"
    ref="img"
  />
  <img v-else class="synoptic-image" :src="src" :style="style" />
</template>

<script>
export default {
  name: "SynopticImage",
  props: {
    control: {
      type: Object,
      required: true
    },
    currentRect: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    src() {
      let src =
        this?.control?.synopticComponent?.tmp?.src ||
        this?.control?.synopticComponent?.src ||
        this?.control?.synopticComponent?.img ||
        (this.mode == "editor"
          ? "/static/common/images/missing-image.svg"
          : "");
      if (this.printout) {
        src += `${src.indexOf("?") >= 0 ? "&" : "?"}t=${new Date().getTime()}`;
      }
      return src;
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlStyle() {
      let style = Object.assign({}, this.control.synopticComponent.style, {});
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return {
        ...style,
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation
        ) || 0}deg)`
      };
    },
    style() {
      let style = {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
      if (!this.src) {
        style.display = "none";
      }
      return style;
    },
    printout() {
      return this.$store.getters.print;
    }
  },
  created() {
    this.$emit("hasContent", true);
  }
};
</script>

<style scoped>
/* status icon component */
img.synoptic-image {
  padding: 0;
  margin: 0;
  border: 0;
}
</style>
