<template>
  <div class="item">
    <div class="mini-toolbar" :class="{expanded: !collapsed}">
      <div
        class="clicable no-select item-title"
        @click.stop.prevent="onClick"
        :title="info"
      >
        <i
          class="item-icon"
          :class="
            checkable
              ? form.checked
                ? 'fa fa-check-square-o'
                : 'fa fa-square-o'
              : collapsed
              ? icon
              : ''
          "
        ></i>
        <span v-if="collapsed" :class="{disabled: !enabled}">
          {{
            (collapsed &&
              form.chartOptions &&
              form.chartOptions.name &&
              getDataLabel) ||
              "-"
          }}
        </span>
        <span v-else>{{ `${$t("type")} ${$t("and")} ${$t("name")}` }}</span>
      </div>
      <div class="clicable no-select item-toolbar">
        <span
          v-if="canRemove && !collapsed"
          class="btn btn-xs"
          v-on:click.stop.prevent="$emit('cloneData', index)"
          :title="$t('clone')"
        >
          <i class="fa fa-copy"></i>
        </span>
        <span
          v-if="!checkable"
          class="btn btn-xs"
          v-on:click.stop.prevent="enabled = !enabled"
        >
          <i class="fa fa-eye" v-if="enabled"></i>
          <i class="fa fa-eye-slash" v-else></i>
        </span>
        <span
          v-if="canRemove"
          class="btn btn-xs"
          v-on:click.stop.prevent="$emit('removeData', index, $event)"
        >
          <i class="fa fa-trash"></i>
        </span>
        <span
          class="btn btn-xs"
          v-on:click.stop.prevent="toggle()"
          style="margin-left: 6px; width: 32"
        >
          <i :class="collapsed ? 'fa fa-angle-right' : 'fa fa-angle-down'"></i>
        </span>
      </div>
    </div>
    <div class="form-container" v-if="!collapsed">
      <div
        class="form-group"
        :title="($refs.btnsim && $refs.btnsim.tooltip) || $t('name')"
      >
        <label v-if="isStandardCurve" class="label-standard-curve">
          <i class="fa fa-check-square-o"></i>
          {{ $t("titles.standard_curve") }}
          <Tooltip :title="$t('hints.standard_curve')" />
        </label>
        <div class="input-group">
          <div
            class="input-group-addon input-group-btn"
            style="styleCurveSelect"
          >
            <button
              type="button"
              class="btn btn-sm dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="!changeType"
              :title="$tc(form.chartOptions.type, 1)"
              style="padding: 0"
            >
              <i :class="icon"></i>
              <span
                class="caret"
                v-if="changeType"
                style="margin-left: 10px"
              ></span>
            </button>
            <ul class="dropdown-menu">
              <li v-for="item in typeList" :key="item.id">
                <a href="#" @click.prevent="onChangeType(item.id)">
                  <i :class="item.icon"></i>
                  {{ $tc(item.id, 1) }}</a
                >
              </li>
            </ul>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="form.chartOptions.name"
            :title="($refs.btnsim && $refs.btnsim.tooltip) || $t('name')"
          />
          <DataSimulationToggle
            v-if="!isStandardCurve"
            class="input-group-addon"
            ref="btnsim"
            :equipmentData="equipmentData"
            :namedQuery="namedQuery"
            :asButton="true"
          />
        </div>
      </div>
      <div class="accordion-wrapper">
        <!-- begin common fields -->
        <TogglePanel :title="$t('appearance')" accordion="serie_common_fields">
          <!-- begin pie -->
          <template v-if="form.chartOptions.type == 'pie'">
            <div>
              <div class="row">
                <!-- begin size -->
                <div class="col-xs-6">
                  <div class="form-inline">
                    <div
                      class="form-group form-group-sm"
                      style="white-space: nowrap"
                    >
                      <label
                        class="label-inline small"
                        for="radius"
                        style="margin-right: 5px"
                      >
                        % {{ $t("size") }}
                      </label>
                      <input
                        id="radius"
                        type="number"
                        min="10"
                        max="90"
                        class="form-control text-center no-padding"
                        v-model="radius"
                      />
                    </div>
                  </div>
                </div>
                <!-- offset label format -->
                <!-- begin offset -->
                <div class="col-xs-6 no-padding">
                  <div class="form-inline">
                    <div
                      class="form-group form-group-sm"
                      style="white-space: nowrap"
                    >
                      <label
                        class="label-inline small"
                        for="centerX"
                        style="margin: 0 3px 0 7px"
                      >
                        {{ $t("offset") }}
                      </label>
                      <input
                        id="centerX"
                        type="number"
                        min="10"
                        max="90"
                        class="form-control text-center no-padding"
                        style="width: 34px; display: inline-block"
                        v-model="centerX"
                      />
                      <input
                        id="centerY"
                        type="number"
                        min="10"
                        max="90"
                        class="form-control text-center no-padding"
                        style="width: 34px; display: inline-block"
                        v-model="centerY"
                      />
                    </div>
                  </div>
                </div>
                <!-- end offset -->
              </div>
            </div>
          </template>
          <!-- end pie -->
          <!-- begin line/bar -->
          <template v-else>
            <div>
              <div class="row">
                <div class="col-xs-12">
                  <div class="form-group form-group-sm">
                    <label for="">
                      {{ $tc("color", 1) }} /
                      {{ $t("synoptic.events.actions.effect") }}
                    </label>
                    <div class="input-group">
                      <div
                        class="input-group-addon no-padding"
                        style="position:relative;"
                      >
                        <ColorPicker
                          style="white-space: normal; margin-left: 0; display: inline-block"
                          class="color-picker-inline no-padding"
                          v-model="form.chartOptions.itemStyle.color"
                          :pickerStyle="{left: '-200px'}"
                          icon="foreground"
                        />
                      </div>
                      <div class="form-control text-center">
                        <label
                          class="checkbox-inline no-select"
                          style="font-size: 1.2em"
                        >
                          <input
                            type="checkbox"
                            v-model="form.chartOptions.animation"
                          />
                          {{ $t("animation") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- begin line -->
              <template v-if="form.chartOptions.type == 'line'">
                <div class="row">
                  <div class="col-xs-12">
                    <div class="form-group form-group-sm">
                      <label
                        class="no-wrap checkbox-inline checkbox-inline-option no-select"
                        :title="$tc('area', 1)"
                      >
                        <input type="checkbox" v-model="showArea" />
                        {{ $t("area") }}
                        <span v-if="showArea"
                          >/ {{ $t("synoptic.opacity") }}:</span
                        >
                      </label>
                      <input
                        :disabled="!showArea"
                        ref="areaOpacity"
                        type="number"
                        class="form-control text-center"
                        min="0"
                        max="1"
                        step="0.1"
                        :placeholder="$t('auto')"
                        v-model="areaOpacity"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-6">
                    <div class="form-group form-group-sm">
                      <label
                        class="no-wrap"
                        :title="$tc('wave_form', 1)"
                        for="wave_form"
                        >{{ $t("wave_form") }}</label
                      >
                      <select
                        id="wave_form"
                        class="form-control text-center no-padding"
                        v-model="form.chartOptions.lineStyle.waveForm"
                      >
                        <option
                          :value="item"
                          v-for="(item, ix) in waveForms"
                          :key="ix"
                        >
                          {{ $t(item) }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="form-group form-group-sm">
                      <label for="thickness">{{ $t("thickness") }}</label>
                      <select
                        id="thickness"
                        class="form-control text-center no-padding"
                        v-model="form.chartOptions.lineStyle.width"
                      >
                        <option
                          :value="item"
                          v-for="(item, ix1) in sizes"
                          :key="ix1"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <ChartSymbolForm v-model="serieSymbol" />
              </template>
              <!-- end line -->
              <!-- begin bar -->
              <template v-if="form.chartOptions.type == 'bar'">
                <div class="row">
                  <div class="col-xs-7">
                    <div class="form-group form-group-sm">
                      <label
                        class="no-wrap checkbox-inline checkbox-inline-option no-select"
                        :title="$tc('background', 1)"
                      >
                        <input type="checkbox" v-model="showBackground" />
                        {{ $t("background") }}
                        <span v-if="showBackground">
                          / {{ $t("synoptic.opacity") }}:</span
                        >
                      </label>
                      <div class="input-group">
                        <div class="input-group-addon no-padding">
                          <ColorPicker
                            style="white-space: normal; margin-left: 0; display: inline-block"
                            class="color-picker-inline no-padding"
                            :disabled="!showBackground"
                            v-model="backgroundColor"
                            :pickerStyle="{left: '-200px'}"
                            icon="foreground"
                          />
                        </div>
                        <input
                          :disabled="!showBackground"
                          type="number"
                          class="form-control text-center"
                          min="0"
                          max="1"
                          step="0.1"
                          :placeholder="$t('auto')"
                          v-model="backgroundOpacity"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-5" style="padding-right:0">
                    <label
                      style="margin-top:25px"
                      class="checkbox-inline checkbox-inline-option no-select no-wrap"
                      :title="$tc('synoptic.shadow', 1)"
                    >
                      <input type="checkbox" v-model="backgroundShadow" />
                      {{ $tc("synoptic.shadow", 1) }}
                    </label>
                  </div>
                </div>
              </template>
              <!-- begin scatter -->
              <template v-if="form.chartOptions.type == 'scatter'">
                <ChartSymbolForm v-model="serieSymbol" />
              </template>
              <!-- end scatter -->
              <div class="row">
                <!-- z-index begin -->
                <div class="col-xs-6">
                  <div class="form-group form-group-sm">
                    <label for="" class="no-wrap">Z-Index</label>
                    <input
                      type="number"
                      class="form-control text-center"
                      :placeholder="`${$t('auto')} (2)`"
                      v-model="zIndex"
                    />
                  </div>
                </div>
                <!-- z-index end -->
                <!-- axis index begin -->
                <div class="col-xs-6" v-if="yAxis">
                  <div class="form-group form-group-sm">
                    <label for="" class="no-wrap">{{ $t("y_axle") }}</label>
                    <select
                      class="form-control no-padding text-center"
                      v-model="yAxisIndex"
                      :disabled="yAxis.length == 1"
                    >
                      <option
                        v-for="(item, index) in yAxis || []"
                        :key="index"
                        :value="index"
                      >
                        {{ $t("y_axle") + " " + (index + 1) }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- axis index end -->
              </div>
            </div>
          </template>
          <!-- end line/bar -->
          <div class="row">
            <div class="col-xs-12">
              <div style="margin-top:5px;">
                <label>{{ $t("visibility") }}</label>
              </div>
              <div class="form-group form-group-sm">
                <label
                  class="no-wrap checkbox-inline checkbox-inline-option no-select"
                  :title="$t('hide_show') + ' ' + $tc('rich_text.legend', 1)"
                >
                  <input type="checkbox" v-model="showInLegend" />
                  {{ $tc("rich_text.legend", 1) }}
                </label>
                <label
                  v-if="form.chartOptions.type != 'pie'"
                  class="no-wrap checkbox-inline checkbox-inline-option no-select"
                  :title="$t('hide_show') + ' ' + $tc('titles.tooltip', 1)"
                >
                  <input type="checkbox" v-model="showInTooltip" />
                  {{ $t("titles.tooltip") }}
                </label>
              </div>
            </div>
          </div>
        </TogglePanel>
        <!-- begin label configuration -->
        <TogglePanel :title="$t('label')" accordion="serie_common_fields">
          <div class="row">
            <div class="col-xs-12">
              <label
                class="checkbox-inline checkbox-inline-option no-select"
                :title="
                  `${$tc('visible', 1)} ${$t('or')} ${$tc('invisible', 1)}`
                "
              >
                <input type="checkbox" v-model="labelShow" />
                {{ $t("visible") }}
              </label>
            </div>
            <template v-if="labelShow">
              <!-- begin label format -->
              <div class="form-group form-group-sm col-xs-12">
                <label for="label_format"
                  >{{ $tc("color", 1) }} {{ $t("and") }} {{ $t("format") }}
                  <Tooltip
                    :title="$t('hints.echarts.label_format')"
                    position="right"
                  />
                </label>
                <div class="input-group">
                  <div
                    class="input-group-addon btn addon-btn"
                    style="padding: 0px 1px 0 3px"
                  >
                    <ColorPicker
                      style="white-space: normal; margin-left: 0; display: inline-block"
                      class="color-picker-inline no-padding"
                      :pickerStyle="{left: '-80px'}"
                      v-model="labelColor"
                      :title="`${$tc('color', 1)}`"
                    />
                  </div>
                  <!-- TODO: add tooltip http://echarts.apache.org/en/option.html#series-pie.label.formatter -->
                  <input
                    class="form-control"
                    :placeholder="$t('format')"
                    v-model="labelFormat"
                  />
                </div>
              </div>
              <!-- end label format -->
              <!-- begin label position -->
              <div class="form-group form-group-sm col-xs-12">
                <label for="labelPosition">{{ $t("position") }}</label>
                <select
                  class="form-control"
                  id="labelPosition"
                  v-model="labelPosition"
                >
                  <template v-if="form.chartOptions.type == 'pie'">
                    <option value="inside">{{ $t("inside") }}</option>
                    <option value="center">{{ $t("center") }}</option>
                    <option value="outside">{{ $t("outside") }}</option>
                  </template>
                  <template v-else>
                    <option value="top">{{ $t("top") }}</option>
                    <option value="left">{{ $t("left") }}</option>
                    <option value="right">{{ $t("right") }}</option>
                    <option value="bottom">{{ $t("bottom") }}</option>
                    <option value="inside">{{ $t("inside") }}</option>
                  </template>
                </select>
              </div>
              <!-- end label position -->
              <!-- begin label font -->
              <div class="col-xs-12">
                <FontSelector
                  v-model="labelFont"
                  :noTextDecoration="true"
                  :allowedUnits="['%']"
                  :editable="customFont"
                />
              </div>
              <!-- end label font -->
              <!-- begin label filter expression-->
              <div class="form-group form-group-sm col-xs-12">
                <div>
                  <label>
                    {{ $tc("filter", 1) }}
                    <Tooltip :title="$t('hints.chart_label_filter')" />
                  </label>
                </div>
                <div class="input-group" style="clear: both">
                  <div class="input-group-addon">
                    <i class="fa fa-filter"></i>
                  </div>
                  <input
                    class="form-control"
                    type="text"
                    v-model="labelFilterExpression"
                    placeholder="$value > 5 || !($index % 5)"
                    name="validation"
                  />
                </div>
              </div>
              <!-- end label filter -->
            </template>
          </div>
        </TogglePanel>
        <!-- end label configuration -->
        <TogglePanel
          :title="`${$tc('titles.transform', 2)}`"
          accordion="serie_common_fields"
        >
          <!-- begin expression -->
          <div class="row">
            <div
              class="form-group form-group-sm col-xs-12"
              style="margin-top: 6px"
            >
              <div>
                <label>
                  {{ $tc("expression", 1) }}
                </label>
                <span
                  class="pull-right"
                  v-if="
                    form.chartOptions.itemStyle.expression && showApplyExpresson
                  "
                >
                  {{ $tc("apply") }}
                  <i
                    class="toolbar-btn clicable"
                    :class="
                      `${icon} ${expressionSlot.chart ? 'text-primary' : ''}`
                    "
                    :title="`${$t('apply_to')} ${$t('chart')}`"
                    @click.stop.prevent="toggleExpression('chart')"
                  ></i>
                  <i
                    class="fa fa-table toolbar-btn clicable"
                    :class="expressionSlot.table ? 'text-primary' : ''"
                    :title="`${$t('apply_to')} ${$t('table')}`"
                    @click.stop.prevent="toggleExpression('table')"
                  ></i>
                </span>
              </div>
              <div class="input-group" style="clear: both">
                <div class="input-group-addon"><i class="fa fa-code"></i></div>
                <input
                  class="form-control"
                  type="text"
                  v-model="form.chartOptions.itemStyle.expression"
                  placeholder="$value * 1000"
                  name="expression"
                />
              </div>
            </div>
          </div>

          <!-- end expression -->
          <!-- begin filter -->
          <div class="row">
            <div
              class="form-group form-group-sm col-xs-12"
              style="margin-top: 6px"
            >
              <div>
                <label>
                  {{ $tc("filter", 1) }}
                </label>
                <span
                  v-if="form.chartOptions.itemStyle.expression"
                  class="pull-right clicable"
                  @click.stop.prevent="
                    validationReadValue = !validationReadValue
                  "
                >
                  {{ $t("read_value") }}
                  <i
                    class="fa toolbar-btn"
                    :class="
                      validationReadValue ? 'fa-check-square-o' : 'fa-square-o'
                    "
                  ></i>
                </span>
              </div>
              <div class="input-group" style="clear: both">
                <div class="input-group-addon">
                  <i class="fa fa-filter"></i>
                </div>
                <input
                  class="form-control"
                  type="text"
                  v-model="form.chartOptions.validation"
                  placeholder="$value > 0"
                  name="validation"
                />
              </div>
            </div>
          </div>
          <!-- end filter -->
          <div
            class="row"
            v-if="showInterpolation && form.chartOptions.type != 'pie'"
          >
            <div class="col-xs-12">
              <div class="form-group form-group-sm">
                <label for="" style="white-space: nowrap">
                  {{ $t("missing_values") }}
                </label>
                <template v-for="(item, ix) in cellInterpolation">
                  <div
                    v-if="item.enabled"
                    :key="ix * 10"
                    class="clicable option"
                    :class="missingValues == item.value ? 'text-primary' : ''"
                    @click.stop.prevent="missingValues = item.value"
                  >
                    <i
                      :class="
                        missingValues == item.value
                          ? 'fa fa-dot-circle-o'
                          : 'fa fa-circle-o'
                      "
                    ></i>
                    {{ $t(item.label) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </TogglePanel>
        <TogglePanel
          v-if="!namedQuery"
          :title="$tc('data_replacement', 1)"
          @open="dataReplacement.show = true"
          @close="dataReplacement.show = false"
        >
          <ControlDataSelector
            v-model="dataReplacement.dataId"
            :parser="dataListParser"
            :connectorId="dataReplacement.connectorId"
            @connectorChanged="dataReplacement.connectorId = $event"
            :expressionToolbar="false"
            :allowedTypes="['bool', 'float', 'int', 'string']"
            label="data_selector"
          >
          </ControlDataSelector>
          <div class="text-center">
            <div
              class="btn btn-primary btn-xs"
              :disabled="!canReplaceData"
              @click.stop.prevent="onReplaceData"
            >
              {{ $t("apply") }}
            </div>
          </div>
        </TogglePanel>
      </div>
      <!-- end common fields -->
    </div>
  </div>
</template>

<script>
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import ColorPicker from "@/components/editor/color-picker.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import DataSimulationToggle from "@/components/control-sidebar/property-editors/data-simulation-toggle.vue";
import Tooltip from "@/components/tooltip.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import FontSelector from "@/components/control-sidebar/property-editors/font-selector.vue";
import ChartSymbolForm from "@/components/control-sidebar/property-editors/chart-symbol-form.vue";

const dftLabelFont = () => ({
  fontFamily: "Tahoma",
  fontSize: "80%",
  fontStyle: "normal",
  fontWeight: "normal"
});

const dftBackgroundStyle = () => ({
  color: "#adadad",
  shadowColor: "rgba(0, 0, 0, 0.2)",
  shadowBlur: 0,
  opacity: 0.1
});

export default {
  name: "ChartSerieForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    index: {
      type: Number,
      required: true
    },
    equipmentData: {
      type: Object,
      required: false,
      default: () => null
    },
    changeType: {
      type: Boolean,
      default: true,
      required: false
    },
    checkable: {
      type: Boolean,
      required: false,
      default: false
    },
    types: {
      type: Array,
      required: false,
      default: () => ["line", "bar", "pie", "scatter"]
    },
    symbol: {
      type: Boolean,
      required: false,
      default: true
    },
    showApplyExpresson: {
      type: Boolean,
      required: false,
      default: true
    },
    showInterpolation: {
      type: Boolean,
      required: false,
      default: true
    },
    namedQuery: {
      type: String,
      required: false,
      default: ""
    },
    yAxis: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  components: {
    ColorPicker,
    DataSimulationToggle,
    Tooltip,
    TogglePanel,
    FontSelector,
    ControlDataSelector,
    ChartSymbolForm
  },
  data() {
    return {
      form: null,
      collapsed: true,
      historySearch: false,
      waveForms: ["square", "triangle", "sin"],
      sizes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      cellInterpolation: [
        {
          value: "",
          label: "standard",
          enabled: true
        },
        {
          value: "last_value",
          label: "last_value",
          enabled: true
        },
        {
          value: "linear_interpolation",
          label: "linear_interpolation",
          enabled: true
        }
      ],
      dataReplacement: {
        show: false,
        dataId: 0,
        connectorId: 0
      }
    };
  },
  computed: {
    entry() {
      var entry = JSON.parse(JSON.stringify(SelectableObjects.data));
      if (this.equipmentData) {
        this.$utils.copyIfExists(
          JSON.parse(JSON.stringify(this.equipmentData)),
          entry
        );
        if (this.historySearch) {
          let entries = JSON.parse(
            JSON.stringify(this.$store.getters["history/entries"] || {})
          );
          let history =
            (entries || {})[entry.id] ||
            this.$store.getters["history/defHistoryData"];
          delete history.samples; // it avoids too deep search
          delete history.interval;
          entry = {
            history: {
              ...history
            },
            ...entry
          };
        }
      }
      return entry;
    },
    typeList() {
      return this.types.map((i) => ({
        id: i,
        text: i,
        icon: {
          line: "fa fa-line-chart",
          bar: "fa fa-bar-chart",
          pie: "fa fa-pie-chart",
          scatter: "fa fa-scatter-chart"
        }[i]
      }));
    },
    isStandardCurve() {
      return this?.form?.chartOptions?.datasetSource == "standard_curve";
    },
    icon() {
      return (
        this.typeList.find(
          ({id}) => id == (this?.form?.chartOptions?.type || "line")
        ).icon + (this.isStandardCurve ? " icon-standard-curve" : "")
      );
    },
    enabled: {
      set(value) {
        this.$emit("enable", value);
      },
      get() {
        return this?.form?.enabled ? true : false;
      }
    },
    checked: {
      set(value) {
        this.$emit("check", value);
      },
      get() {
        return this?.form?.checked ? true : false;
      }
    },
    expressionSlot: {
      set(value) {
        this.$set(this.form.chartOptions.itemStyle, "expressionSlot", value);
      },
      get() {
        return (
          this?.form?.chartOptions?.itemStyle?.expressionSlot || {
            chart: true,
            table: false
          }
        );
      }
    },
    validationReadValue: {
      set(value) {
        this.$set(this.form.chartOptions, "validationReadValue", value);
      },
      get() {
        return "validationReadValue" in this?.form?.chartOptions
          ? this?.form?.chartOptions.validationReadValue
          : true;
      }
    },
    missingValues: {
      set(value) {
        this.$set(this.form.chartOptions.itemStyle, "missingValues", value);
      },
      get() {
        let dft =
          this?.chartOptions?.lineStyle?.waveForm == "square"
            ? "last_value"
            : "";
        return this?.form?.chartOptions?.itemStyle?.missingValues || dft;
      }
    },
    referenceConnectorId() {
      return (
        (this.$store.getters["dashboard/screenRefMap"](this.screenId) || {})
          ?.conn1 || 0
      );
    },
    dashboardConnectorId() {
      return this.$store.getters["dashboard/dashboardEquipmentId"];
    },
    info() {
      const _info = (data) => {
        let data_name = data?.name ?? "";
        if (data?.identity_embedded_app) {
          data_name = `${data?.name}\n  ${data?.identity_embedded_app}`;
        }

        return `${data?.device?.connector?.id ?? ""} - ${data?.device?.connector
          ?.name ?? ""}\n → ${data?.device?.id ?? ""} - ${data?.device?.name ??
          ""}\n  → ${data?.id ?? ""} - ${data_name}${
          this.isStandardCurve ? "\n" + this.$t("titles.standard_curve") : ""
        }`;
      };
      let data = this.equipmentData;
      if (data) {
        return _info(data);
      } else if (!data && this.dashboardConnectorId && this.form.data_id) {
        // try to find it from the reference_id
        data = this.$store.getters["dashboard/dataList"].find(
          ({clp_id, id, reference_id}) =>
            parseInt(clp_id) == parseInt(this.dashboardConnectorId) &&
            ((!isNaN(Number(this.form.data_id)) &&
              parseInt(this.form.data_id) == parseInt(id)) ||
              reference_id == this.form.data_id)
        );
        if (!data) {
          // still not found, try to show something...
          let lst = [];
          this.$store.getters["dashboard/dataList"]
            .filter(({reference_id}) => reference_id == this.form.data_id)
            .map((i) => {
              return lst.push(_info(i));
            });
          if (lst.length) {
            return lst.join("\n---\n");
          }
        }
      }
      return this.$t("not_found");
    },
    dataDisplayLabel() {
      return this.$store.getters["dashboard/dataDisplayLabel"];
    },
    canRemove() {
      return !this.namedQuery;
    },
    showArea: {
      set(value) {
        this.$set(this.form.chartOptions.itemStyle, "showArea", value);
      },
      get() {
        return this?.form?.chartOptions?.itemStyle?.showArea ?? false;
      }
    },
    radius: {
      set(value) {
        this.$set(
          this.form.chartOptions,
          "radius",
          this.$utils.onlyNumbers(value) + "%"
        );
      },
      get() {
        return this.$utils.onlyNumbers(this.form?.chartOptions?.radius || "0");
      }
    },
    centerX: {
      set(value) {
        this.form.chartOptions.center = this.form?.chartOptions?.center || [
          "50%",
          "50%"
        ];
        this.$set(
          this.form.chartOptions.center,
          0,
          this.$utils.onlyNumbers(value) + "%"
        );
      },
      get() {
        let center = this.form?.chartOptions?.center || [50, 50];
        return this.$utils.onlyNumbers(center[0] || "0");
      }
    },
    centerY: {
      set(value) {
        this.form.chartOptions.center = this.form?.chartOptions?.center || [
          "50%",
          "50%"
        ];
        this.$set(
          this.form.chartOptions.center,
          1,
          this.$utils.onlyNumbers(value) + "%"
        );
      },
      get() {
        let center = this.form?.chartOptions?.center || [50, 50];
        return this.$utils.onlyNumbers(center[1] || "0");
      }
    },
    labelShow: {
      set(value) {
        this.labelProperty("show", value);
        if (this.form.chartOptions.type !== "pie") {
          this.$set(this.form.chartOptions, "showSymbol", value);
        }
      },
      get() {
        return this.labelProperty("show") || false;
      }
    },
    labelColor: {
      set(value) {
        this.labelProperty("color", value);
      },
      get() {
        return this.labelProperty("color") || "#484848";
      }
    },
    labelFormat: {
      set(value) {
        this.labelProperty("formatter", value);
      },
      get() {
        return this.labelProperty("formatter") || "";
      }
    },
    labelPosition: {
      set(value) {
        this.labelProperty("position", value);
      },
      get() {
        return (
          this.labelProperty("position") ||
          (this.form.chartOptions.type == "pie" ? "inside" : "top")
        );
      }
    },
    customFont() {
      return this.labelProperty("customFont");
    },
    labelFont: {
      set(value) {
        let label = structuredClone(this?.form?.chartOptions?.label || {});
        if (value) {
          if (!label.customFont) {
            label = {...label, ...dftLabelFont(), customFont: false};
            label.customFont = true;
          } else {
            label.fontFamily = value["font-family"];
            label.fontSize = value["font-size"].replace(/\D/g, "") + "%";
            label.fontWeight = value["font-weight"];
            label.fontStyle = value["font-style"];
          }
        } else {
          delete label.fontFamily;
          delete label.fontSize;
          delete label.fontWeight;
          delete label.fontStyle;
          label.customFont = false;
        }
        this.$set(this.form.chartOptions, "label", label);
      },
      get() {
        let dft = dftLabelFont();
        return {
          "font-family": this.labelProperty("fontFamily") ?? dft.fontFamily,
          "font-size": this.labelProperty("fontSize") ?? dft.fontSize,
          "font-style": this.labelProperty("fontStyle") ?? dft.fontStyle,
          "font-weight": this.labelProperty("fontWeight") ?? dft.fontWeight
        };
      }
    },
    labelFilterExpression: {
      set(value) {
        this.labelProperty("filterExpression", value);
      },
      get() {
        return this.labelProperty("filterExpression");
      }
    },
    zIndex: {
      set(value) {
        if (value === "") {
          this.$delete(this.form.chartOptions, "z");
        } else {
          this.$set(this.form.chartOptions, "z", value);
        }
      },
      get() {
        return this?.form?.chartOptions?.z ?? "";
      }
    },
    showBackground: {
      set(value) {
        this.$set(this.form.chartOptions, "showBackground", value);
        if (value) {
          this.$set(
            this.form.chartOptions,
            "backgroundStyle",
            dftBackgroundStyle()
          );
        }
      },
      get() {
        return this?.form?.chartOptions?.showBackground ?? false;
      }
    },
    backgroundColor: {
      set(value) {
        let backgroundStyle = {
          ...dftBackgroundStyle(),
          ...(this?.form?.chartOptions?.backgroundStyle || {})
        };
        backgroundStyle.color = value;
        this.$set(this.form.chartOptions, "backgroundStyle", backgroundStyle);
      },
      get() {
        return (
          this?.form?.chartOptions?.backgroundStyle?.color ??
          dftBackgroundStyle().color
        );
      }
    },
    backgroundOpacity: {
      set(value) {
        let backgroundStyle = {
          ...dftBackgroundStyle(),
          ...(this?.form?.chartOptions?.backgroundStyle || {})
        };
        backgroundStyle.opacity = isNaN(Number(value))
          ? ""
          : Number(value) > 1
          ? 1
          : Number(value);
        this.$set(this.form.chartOptions, "backgroundStyle", backgroundStyle);
      },
      get() {
        return (
          this?.form?.chartOptions?.backgroundStyle?.opacity ??
          dftBackgroundStyle().opacity
        );
      }
    },
    backgroundShadow: {
      set(value) {
        let backgroundStyle = {
          ...dftBackgroundStyle(),
          ...(this?.form?.chartOptions?.backgroundStyle || {})
        };
        if (value) {
          backgroundStyle.shadowBlur = 6;
          backgroundStyle.shadowColor = dftBackgroundStyle().shadowColor;
        } else {
          backgroundStyle.shadowBlur = 0;
          backgroundStyle.shadowColor = "transparent";
        }
        this.$set(this.form.chartOptions, "backgroundStyle", backgroundStyle);
      },
      get() {
        return this?.form?.chartOptions?.backgroundStyle?.shadowBlur ??
          dftBackgroundStyle().shadowBlur
          ? true
          : false;
      }
    },
    areaOpacity: {
      set(value) {
        if (value === "") {
          this.$delete(this.form.chartOptions.itemStyle, "areaOpacity");
        } else {
          let vlr = isNaN(Number(value)) ? "" : Number(value);
          if (vlr > 1) {
            vlr = 1;
            this.$nextTick(() => {
              this.$refs.areaOpacity.value = 1;
            });
          }
          this.$set(this.form.chartOptions.itemStyle, "areaOpacity", vlr);
        }
      },
      get() {
        return this?.form?.chartOptions?.itemStyle?.areaOpacity ?? "";
      }
    },
    yAxisIndex: {
      set(value) {
        if (isNaN(Number(value))) {
          this.$delete(this.form.chartOptions, "yAxisIndex");
        } else {
          this.$set(this.form.chartOptions, "yAxisIndex", Number(value));
        }
      },
      get() {
        return this?.form?.chartOptions?.yAxisIndex ?? 0;
      }
    },
    showInLegend: {
      set(value) {
        this.$set(this.form.chartOptions, "showInLegend", value);
      },
      get() {
        return this?.form?.chartOptions?.showInLegend ?? true;
      }
    },
    showInTooltip: {
      set(value) {
        this.$set(this.form.chartOptions, "showInTooltip", value);
      },
      get() {
        return this?.form?.chartOptions?.showInTooltip ?? true;
      }
    },
    serieSymbol: {
      set(value) {
        let chartOptions = {...this.form.chartOptions};
        chartOptions.symbolSize = value.symbolSize;
        if (value.symbol) {
          chartOptions.symbol = value.symbol;
          chartOptions.showSymbol = true;
        } else {
          delete chartOptions.symbol;
          chartOptions.showSymbol = false;
        }
        this.$set(this.form, "chartOptions", chartOptions);
      },
      get() {
        let entry = null;
        if (this.form?.chartOptions?.symbol) {
          entry = {
            symbol: this?.form?.chartOptions?.symbol ?? null,
            symbolSize: this?.form?.chartOptions?.symbolSize ?? 0
          };
        }
        return entry;
      }
    },
    getDataLabel() {
      if (
        this?.form?.chartOptions?.identity_embedded_app &&
        this.dataDisplayLabel == "identity_embedded_app"
      ) {
        return this.form?.chartOptions?.identity_embedded_app ?? "";
      }
      return this?.form?.chartOptions?.name ?? "";
    },
    canReplaceData() {
      return !this.namedQuery &&
        this.dataReplacement.dataId &&
        (!this.equipmentData ||
          parseInt(this.dataReplacement.dataId) !=
            parseInt(this.equipmentData.id))
        ? true
        : false;
    },
    styleCurveSelect() {
      if (this.$root.isDarkTheme) {
        return {
          border: "1px solid var(--skin-dark-darker)",
          "border-right": "0",
          "border-radius": "2px 0 0 2px"
        };
      }
      return {
        border: "1px solid #d2d6de",
        "border-right": "0",
        "border-radius": "2px 0 0 2px"
      };
    }
  },
  watch: {
    form: {
      handler(n, o) {
        if (!n || !o) return;
        this.$emit("input", n);
      },
      deep: true
    }
  },
  methods: {
    toggleExpression(slot) {
      let entry = JSON.parse(JSON.stringify(this.expressionSlot));
      entry[slot] = !entry[slot];
      this.expressionSlot = entry;
    },
    onClick() {
      if (this.checkable) {
        this.checked = !this.checked;
      } else {
        this.toggle();
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
      this.$emit(this.collapsed ? "close" : "open");
    },
    labelProperty(name, value) {
      let label = this?.form?.chartOptions?.label || {};
      if (value !== undefined) {
        // label = {...label};
        // label[name] = value;
        let chartOptions = {
          ...this.form.chartOptions,
          label: {
            ...label
          }
        };
        chartOptions.label[name] = value;
        // this.$set(this.form.chartOptions, "label", label);
        this.$set(this.form, "chartOptions", chartOptions);
      }
      return (label || {})[name];
    },
    onChangeType(id) {
      this.$emit("changeType", id);
    },
    onReplaceData() {
      if (!this.canReplaceData) return;
      this.$emit("replaceData", this.dataReplacement.dataId);
    },
    dataListParser(lst) {
      return (lst || []).filter(
        ({id, history_enabled, type}) =>
          type !== "string" &&
          (`${id}`.includes("data_group") || history_enabled)
      );
    }
  },
  created() {
    this.form = structuredClone(this.value || {});
    if (this.equipmentData) {
      this.dataReplacement.dataId = this.equipmentData.id;
      this.dataReplacement.connectorId = this.equipmentData.clp_id;
    }
  }
};
</script>

<style scoped>
div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: inherit;
  padding: 4px 0;
  overflow: clip visible;
}

div.mini-toolbar {
  color: #888;
  position: relative;
  padding: 0 5px;
}

body:has(.skin-dark) div.mini-toolbar {
  color: var(--skin-dark-light);
}

div.mini-toolbar.expanded {
  color: #111;
}

body:has(.skin-dark) div.mini-toolbar.expanded {
  color: var(--skin-dark-light);
}

div.item-title {
  font-size: 12pt;
  font-weight: 600;
  white-space: normal;
  width: 100%;
}

div.mini-toolbar.expanded > div.item-title {
  color: #337ab7;
}

body:has(.skin-dark) .mini-toolbar.expanded > div.item-title {
  color: var(--skin-dark-light);
}

i.drag-icon {
  margin: 0 6px 0 -4px;
  color: #999;
}

i.item-icon {
  margin-left: -4px;
  margin-right: 0;
  min-width: 20px;
}

div.item-toolbar {
  position: absolute;
  top: 2px;
  right: 0;
  background: #ffffffcc;
}

body:has(.skin-dark) div.item-toolbar {
  background: transparent;
}

body:has(.skin-dark) div.item-toolbar .btn:hover {
  opacity: 1;
  color: #fff;
}

div.item-toolbar > i {
  padding: 0 3px;
  min-width: 22px;
}

.clicable:hover {
  background-color: transparent;
  cursor: pointer;
  opacity: 0.8;
}

body:has(.skin-dark) .clicable:not(.item-toolbar):hover {
  opacity: 1;
  color: #fff;
}

body:has(.skin-dark) .clicable:not(.item-toolbar):hover i.fa-scatter-chart {
  opacity: 1;
  background-color: #fff;
}

.form-container {
  clear: both;
  padding: 5px 8px 25px 8px;
  background-color: white;
  z-index: 3;
}

body:has(.skin-dark) .form-container {
  background: transparent;
}

.form-group {
  margin-bottom: 4px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.input-group-btn > .dropdown-toggle {
  background-color: transparent;
  display: block;
  padding: 5px 0;
}

/* chart type dropdown */
.btn.dropdown-toggle.btn-sm[disabled] {
  opacity: 1;
  cursor: revert;
}

.toolbar-btn {
  margin: 0 2px 0 5px;
  min-width: 16px;
}

div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

.color-picker-inline {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}

label {
  font-weight: 600;
}

label.no-wrap {
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.checkbox-inline-option {
  font-weight: 600;
  margin-top: 0.2em;
}

.common-fields {
  margin-top: -5px;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  padding: 5px 10px 0 10px;
}

.accordion-wrapper {
  padding: 0 10px;
}

.fa-scatter-chart {
  margin-top: 2px;
}

.icon-standard-curve {
  /* background: greenyellow; */
  /* font-size: 88%;
  padding: 0.2em; */
  /* color: #a94442; */
  /* border-radius: 2px; */
}

.dropdown-toggle > .icon-standard-curve {
  font-size: inherit;
}

.label-standard-curve {
  margin: 5px 0 -5px 0;
  color: #666;
  cursor: not-allowed;
  width: 100%;
  text-align: right;
  font-size: 90%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
span.disabled {
  opacity: 0.6;
}

body:has(.skin-dark) .fa-scatter-chart {
  background-color: var(--skin-dark-light);
}

body:has(.skin-dark) li:hover > a > i.fa-scatter-chart {
  background-color: #fff;
}
</style>
