<template>
  <div class="me" v-if="control.synopticComponent" :style="style">
    <span
      :class="{
        clicable: hasWritePermission && this.lastData && this.lastData.enabled,
        busy: busy,
        disabled: busy || isDisabled
      }"
      v-on:click.prevent.stop="onClick"
      :style="{color: color}"
      id="toggle"
    >
      <i class="fa fa-toggle-on strong" v-if="isActive"></i>
      <i class="fa fa-toggle-off strong" v-else></i>
      <span class="text">{{ label }}</span>
    </span>
    <SynopticDataValueTip
      title=""
      :entry="lastData"
      :value="value"
      :error="error"
      :position="control.synopticComponent.commandStatus"
      :hasPendingCommand="hasPendingCommand"
      style="z-index: 1"
    />
  </div>
</template>

<script>
import SynopticDataValueBase from "./synoptic-data-value-base.vue";
import SynopticDataValueTip from "./synoptic-data-value-tip.vue";
export default {
  name: "SynopticDataValueToggle",
  extends: SynopticDataValueBase,
  components: {
    SynopticDataValueTip
  },
  computed: {
    isActive() {
      return (
        parseInt(this.value) ==
        parseInt(this?.control?.synopticComponent?.values?.active?.value || 0)
      );
    },
    label() {
      return this.isActive
        ? this?.control?.synopticComponent?.values?.active?.label || ""
        : this?.control?.synopticComponent?.values?.inactive?.label || "";
    },
    color() {
      return this.error
        ? this.control.synopticComponent.errorStyle?.color ?? "#a94442"
        : this.isActive
        ? this?.control?.synopticComponent?.values?.active?.color || "#ff0000"
        : this?.control?.synopticComponent?.values?.inactive?.color ||
          "#0d0d0d";
    },
    localError() {
      if (
        this.values.length &&
        this?.lastData?.current_value &&
        this.values.indexOf(Number(this.value)) == -1
      ) {
        return this.$t("invalid_value") + " (" + this.value + ")";
      }
      return "";
    },
    values() {
      return Object.values(
        this?.control?.synopticComponent?.values || {}
      ).map((i) => Number(i.value));
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        "--text-size-adjust": this.$utils.iOS() ? "35%" : "70%"
      };
    }
  },

  methods: {
    onClick() {
      if (this.busy || this.isDisabled) return;
      this.skipRemoteUpdate = true;
      let prv = this.value;
      let vlr = this.isActive
        ? this.control.synopticComponent.values.inactive.value
        : this.control.synopticComponent.values.active.value;
      this.manuallyChanged = this.rawValue != vlr;
      this.value = vlr;
      this.save()
        .then(() => {})
        .catch(() => {
          this.value = prv; // restore previous value if something went wrong
        });
    },
    onPendingCommandChanged() {
      // important: keep this method empty to avoid overwriting the value
    }
    // onTimeStampChanged() {
    //   console.log(
    //     `${this.$options.name} ${this.isDirty} ${this.formSaving} ${this.lastData.current_value.value} ${this.iValue}`
    //   );
    //   if (this.hasPendingCommand || this.hasFocus) return;
    //   if (
    //     (this.requireRealTimeUpdate && this.isValid) ||
    //     (this.isDirty && (!this.hasActiveForm || this.formSaving)) ||
    //     !this.isDirty
    //   ) {
    //     this.restore();
    //   }
    // }
  }
};
</script>

<style scoped>
.me {
  z-index: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust);
}

.me #toggle {
  width: 100%;
}

.text {
  font-size: 95%;
  padding-left: 10px;
}

.clicable:hover {
  opacity: 0.6;
  cursor: pointer;
  z-index: 1;
}

.disabled:hover {
  cursor: not-allowed;
}
.busy {
  opacity: 0.5;
}
</style>
