var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mode == 'editor' && _vm.control.enabled)?_c('vue-resizable',_vm._b({staticClass:"synoptic-control-container",class:_vm.controlClasses,attrs:{"zoom":_vm.zoom,"active":_vm.active,"dragSelector":_vm.dragSelector,"fit-parent":false},on:{"resize:start":_vm.eHandler,"resize:move":_vm.eHandler,"resize:end":_vm.eHandler,"drag:start":_vm.eHandler,"drag:move":_vm.eHandler,"drag:end":_vm.eHandler}},'vue-resizable',_vm.vueResizableRect,false),[_c('div',{staticClass:"synoptic-control",class:_vm.iclass,attrs:{"title":`${_vm.lastData ? _vm.control.name + '\n' + _vm.title : _vm.control.name} ${
        _vm.isLocked ? '\n' + _vm.$t('titles.editing_not_allowed') : ''
      }`},on:{"mousedown":[function($event){if(!$event.ctrlKey)return null;if($event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.toggleSelection(_vm.control.id)},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.select()}]}},[_c('div',{staticClass:"draggable-container",style:({'z-index': _vm.isEditing ? 'auto' : 3}),on:{"dblclick":_vm.onDoubleClick}}),_c(_vm.widget,{ref:"innerComponent",tag:"component",attrs:{"control":_vm.control,"equipment":_vm.equipment,"lastData":_vm.lastData,"currentRect":_vm.controlRect,"zoom":_vm.zoom},on:{"hasContent":_vm.onHasContent,"dataChange":_vm.onDataChange}})],1),(
      _vm.isSelected && _vm.controlClasses.selected && !_vm.isLocked && _vm.rotationEnabled
    )?_c('span',{directives:[{name:"slidable",rawName:"v-slidable",value:({
      onUpdate: _vm.rotate,
      onBeginSlide: _vm.stopRecording,
      onEndSlide: _vm.resumeRecording,
      vertical: true
    }),expression:"{\n      onUpdate: rotate,\n      onBeginSlide: stopRecording,\n      onEndSlide: resumeRecording,\n      vertical: true\n    }"}],staticClass:"fa fa-rotate-left rotate",on:{"dblclick":function($event){_vm.rotation = 0}}}):_vm._e()]):(_vm.isVisible && _vm.userHasViewPermission)?_c('div',{staticClass:"synoptic-control-container",class:_vm.viewerModeClasses,style:({
    ...{
      top: `${_vm.vueResizableRect.top}px`,
      left: `${_vm.vueResizableRect.left}px`,
      height: `${_vm.vueResizableRect.height}px`,
      width: `${_vm.vueResizableRect.width}px`
    },
    ..._vm.compatibleStyle
  })},[(!_vm.busy)?_c('div',{staticClass:"synoptic-control",class:_vm.iclass,attrs:{"title":_vm.title},on:{"!click":function($event){return _vm.checkInteractionPermission.apply(null, arguments)},"!dblclick":function($event){return _vm.checkInteractionPermission.apply(null, arguments)},"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onCustomEvent('click')},"dblclick":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onCustomEvent('dblclick')}}},[_c(_vm.widget,{ref:"innerComponent",tag:"component",class:_vm.controlClasses,attrs:{"control":_vm.control,"equipment":_vm.equipment,"lastData":_vm.lastData,"currentRect":_vm.controlRect,"zoom":_vm.zoom,"disabled":_vm.isCSSDisabled},on:{"hasContent":_vm.onHasContent,"dataChange":_vm.onDataChange,"click":function($event){return _vm.validateAndTrigger('click')},"dblclick":function($event){return _vm.validateAndTrigger('dblclick')}}}),(
        _vm.control.enabled &&
          _vm.control.synopticComponent &&
          _vm.control.synopticComponent.on
      )?_c('PendingActionsIndicator',{ref:"pendingActionsIndicator",attrs:{"controlEvents":_vm.control.synopticComponent.on,"draggable":true}}):_vm._e()],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }