<template>
  <section>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group form-group-sm">
          <div class="input-group">
            <div class="inline-field" style="width: 33%" :title="$t('minimum')">
              <label class="inline-field-label">{{ $t("minimum") }}</label>
              <input
                type="number"
                placeholder="auto"
                class="form-control inner-field-input"
                @input="axisProp('min', $event.target.value)"
                :value="axisProp('min')"
              />
            </div>
            <div class="inline-field" style="width: 33%" :title="$t('maximum')">
              <label class="inline-field-label">{{ $t("maximum") }}</label>
              <input
                type="number"
                placeholder="auto"
                class="form-control inner-field-input"
                @input="axisProp('max', $event.target.value)"
                :value="axisProp('max')"
              />
            </div>
            <div
              class="inline-field"
              style="width: 34%"
              :title="$t('increment')"
            >
              <label class="inline-field-label">{{ $t("increment") }}</label>
              <input
                type="number"
                placeholder="auto"
                class="form-control inner-field-input"
                @input="axisProp('interval', $event.target.value)"
                :value="axisProp('interval')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group form-group-sm" style="margin-top:-22px">
          <div class="input-group">
            <div class="input-group-addon small-addon">
              Offset
            </div>
            <input
              id="axisOffset"
              type="text"
              class="form-control text-center"
              placeholder="auto"
              @input="axisProp('offset', $event.target.value)"
              :value="axisProp('offset')"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {isEqual} from "lodash";

const axisProp = ($vm, form, name, value) => {
  form = form || {};
  var vlr = "";
  if (value === undefined) {
    if (name in form) {
      vlr = form[name];
    }
  } else {
    if (value === "" || value === "auto") {
      vlr = "";
    } else if (value === true || value === false) {
      vlr = value;
    } else {
      vlr = isNaN(Number(value)) ? "" : parseFloat(value);
    }
    form = {...form};
    form[name] = vlr;
    if ($vm) {
      $vm.setForm(form);
    }
  }
  return vlr === "auto" ? "" : vlr;
};

const resetScale = (
  form,
  props = ["min", "max", "interval", "dataSerieId", "offset"]
) => {
  props.forEach((p) => delete form[p]);
};

export {axisProp, resetScale};
export default {
  name: "ChartAxisScaleForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {},
  methods: {
    axisProp(name, value) {
      return axisProp(this, this.form, name, value);
    },
    setForm(payload) {
      if (payload && this.form && !isEqual(this.form, payload)) {
        this.$set(this, "form", payload);
        this.$emit("input", this.form);
      }
    }
  },
  created() {
    this.form = {...this.value};
  }
};
</script>

<style scoped>
.inline-field {
  position: relative;
  display: inline-block;
}

.inline-field > input {
  z-index: 0;
  background-color: transparent;
  text-align: center;
  padding: 8px 0 0 0;
}

.inline-field > label {
  position: absolute;
  top: -2px;
  left: 3px;
  z-index: 1;
  font-size: 8pt;
  font-weight: normal;
  margin: 0;
  color: #777;
}

.scope-block {
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dfdfdf;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.small-addon {
  font-size: 8pt;
  font-weight: normal;
  margin: 0;
  color: #777;
}

.skin-dark .small-addon {
  color: var(--skin-dark-light);
}

.skin-dark .inline-field > input::placeholder,
.skin-dark .inline-field > input::-ms-input-placeholder {
  color: var(--skin-dark-light);
}

.skin-dark .inline-field > label {
  color: var(--skin-dark-semilight);
}
</style>
