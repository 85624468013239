<template>
  <div class="me">
    <!-- <div class="icon-container">
      <img class="control-icon" :src="thumbnail" />
    </div> -->
    <input
      type="text"
      v-model="text"
      :placeholder="placeHolder"
      ref="inp"
      :disabled="disabled"
      @blur="onInpBlur"
      @dblclick="disabled ? toggleInput() : () => {}"
      @click.stop.prevent
      @keydown.tab.exact.prevent.stop
      @keydown.ctrl.tab.exact.prevent.stop
      @keydown.shift.tab.exact.prevent.stop
      @keydown.esc.prevent.stop="onInpBlur"
    />
    <span class="toolbar text-primary pull-right">
      <i
        class="fa fa-pencil"
        :class="!disabled ? 'disabled' : ''"
        @click.stop.prevent="disabled ? toggleInput() : () => {}"
      />
      <i
        :class="control.locked || panelLocked ? 'fa fa-lock' : 'fa fa-unlock'"
        @click.stop.prevent="toggleLock"
      />
    </span>
  </div>
</template>

<script>
import GalleryItems from "@/assets/dashboard/controls.json";

export default {
  name: "ControlHeader",
  data() {
    return { disabled: true, bluring: false };
  },
  computed: {
    selectedControls() {
      return this.$store.getters["synoptic/selectedControls"] || [];
    },
    panelLocked() {
      return this.$store.getters["synoptic/isPanelLocked"];
    },
    control() {
      return this?.selectedControls?.length ? this.selectedControls[0] : null;
    },
    controlId() {
      return this?.control?.id || "";
    },
    originalCtrl() {
      if (!this?.control) return null;
      return GalleryItems.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          this?.control?.synopticComponent?.componentName
      );
    },
    text: {
      set(value) {
        if (!this.control) return;
        let control = { ...this.control };
        control.name = value;
        this.updateControl(control);
      },
      get() {
        return (
          this?.control?.name ||
          (this.disabled ? this?.originalCtrl?.template?.title : "")
        );
      }
    },
    thumbnail() {
      return this?.originalCtrl?.thumbnail || "";
    },
    placeHolder() {
      return this.text || this?.originalCtrl?.template?.title || "text";
    }
  },
  watch: {
    controlId: {
      handler(n, o) {
        // if (n) {
        //   if (this.text != this?.control?.name || !o) {
        //     this.text = this.control?.name ||
        //       this.control.title ||
        //       this?.originalCtrl?.template?.title ||
        //       n?.synopticComponent?.componentName;
        //   }
        // }
      },
      immediate: true
    }
  },
  methods: {
    toggleLock() {
      let control = JSON.parse(JSON.stringify(this.control));
      control.locked = !control.locked;
      this.updateControl(control);
    },
    toggleInput() {
      if (this.bluring) {
        this.bluring = false;
        return;
      }
      this.disabled = !this.disabled;
      if (!this.disabled) {
        this.$nextTick(() => {
          this.$refs.inp.focus();
        });
      }
    },
    updateControl(control) {
      this.$store.dispatch("synoptic/updateControl", {
        id: control.id,
        control
      });
    },
    onInpBlur() {
      this.disabled = true;
      this.bluring = true;
      setTimeout(
        () => {
          this.bluring = false;
        },
        200,
        this
      );
    }
  }
};
</script>

<style scoped>
.me {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 20px;
}

.icon-container {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 2px;
  margin-top: 1px;
  opacity: 0.9;
}

.control-icon {
  width: 100%;
  filter: grayscale(1) contrast(1);
}

.label-container {
  vertical-align: middle;
}

.toolbar {
  position: absolute;
  right: 3px;
  z-index: 99;
  cursor: pointer;
}

.toolbar > i {
  margin: 0 6px;
  min-width: 16px;
}

.toolbar > i:hover {
  opacity: 0.8;
}

.skin-dark .toolbar > i {
  opacity: 1;
  color: var(--skin-dark-light);
}

.skin-dark .toolbar > i:hover {
  color: var(--skin-dark-white);
}

input {
  position: absolute;
  top: 0;
  left: 2px;
  padding-left: 18px;
  width: auto;
  height: 100%;
  border: none !important;
  outline-width: 0;
  padding: 0 60px 0 22px;
  color: inherit;
  background-color: transparent;
  vertical-align: middle;
  z-index: 1;
}

input:focus {
  outline-width: 0;
  color: #000;
  text-shadow: 1px 0 0 #aaa;
}

i.disabled {
  color: #666;
  cursor: not-allowed;
}

input::-ms-input-placeholder {
  /* Edge 12-18 */
  opacity: 0.5;
}

input::placeholder {
  opacity: 0.5;
}
</style>
