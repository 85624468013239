<template>
  <section v-if="form">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group form-group-sm">
          <label for="axisName">
            {{ $t("text") }}
          </label>
          <div class="input-group">
            <input
              id="axisName"
              ref="axisName"
              type="text"
              class="form-control"
              placeholder="auto"
              v-model="axisName"
            />
            <div
              class="input-group-addon btn"
              :title="$t('clear')"
              @click.stop.prevent="
                axisName = '';
                focus();
              "
            >
              <i class="fa fa-close"></i>
            </div>
            <div
              class="input-group-addon btn"
              @click.stop.prevent="nextNamePosition"
              :title="selectedNameLocation.title"
            >
              <i :class="selectedNameLocation.icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <div class="form-group form-group-sm">
          <label for="nameGap">
            Offset <Tooltip :title="$t('hints.offset')" />
          </label>
          <input
            id="nameGap"
            type="text"
            class="form-control text-center"
            placeholder="auto"
            v-model="axisNameGap"
          />
        </div>
      </div>
      <div class="col-xs-8">
        <label for="axisNameRotate" style="width: 100%; position: relative">
          {{ $t("synoptic.rotation") }}: {{ axisNameRotate }}°
          <span
            class="clicable"
            :title="$t('reset')"
            style="position: absolute; right: 0; top: 2px; font-size: 80%"
            @click="axisNameRotate = 0"
          >
            <i class="fa fa-undo"></i>
          </span>
        </label>
        <div class="form-group form-group-sm no-select">
          <input
            style="padding: 0 5px"
            type="range"
            min="-90"
            max="90"
            v-model="axisNameRotate"
            id="axisNameRotate"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div>
          <label style="white-space: nowrap" class="checkbox-inline no-select">
            <input type="checkbox" v-model="axisNameDynamicColor" />
            {{ $tc("dynamic_color", 1) }}
            <Tooltip :title="$t('hints.dynamic_color')" />
          </label>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "ChartAxisTitleForm",
  components: {
    Tooltip
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    nameLocationCfgOptions: {
      type: Array,
      required: true
    },
    defaultLocationIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      form: null,
      axisNameRotation: 0,
      locationPos: this.defaultLocationIndex
    };
  },
  computed: {
    axisName: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form};
        form.name = value;
        if (!form.nameLocation) form.nameLocation = "middle";
        this.setForm(form);
      },
      get() {
        return this?.form?.name || "";
      }
    },
    axisNameGap: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form};
        form.nameGap =
          value == "-" ? "-" : isNaN(Number(value)) ? "" : Number(value);
        this.setForm(form);
      },
      get() {
        return this?.form?.nameGap || "";
      }
    },
    axisNameLocation: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form};
        form.nameLocation = value;
        this.setForm(form);
      },
      get() {
        return this?.form?.nameLocation || "middle";
      }
    },
    axisNameRotate: {
      set(value) {
        if (!this.form) return;
        this.axisNameRotation = value;
        let form = {...this.form};
        form.nameRotate = value;
        this.setForm(form);
      },
      get() {
        return this.axisNameRotation || 0;
      }
    },
    axisNameDynamicColor: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form};
        form.dynamicColor = value;
        this.setForm(form);
      },
      get() {
        return this?.form?.dynamicColor || false;
      }
    },
    selectedNameLocation() {
      return (this.nameLocationCfgOptions || [])[this.locationPos];
    }
  },
  methods: {
    setForm(payload) {
      if (payload && this.form && !isEqual(this.form, payload)) {
        this.form = payload;
        this.$emit("input", this.form);
      }
    },
    init() {
      const _initLocal = (name, value, dft) => {
        this[name] = (value ?? "") === "" ? dft : value;
      };
      this.form = {
        name: this?.value?.name ?? "",
        nameGap: this?.value?.nameGap ?? "",
        nameLocation: this?.value?.nameLocation ?? "",
        nameRotate: this?.value?.nameRotate ?? 0,
        dynamicColor: this?.value?.dynamicColor ?? false
      };
      _initLocal("axisNameRotation", this?.form?.nameRotate, 0);
      let pos = this.nameLocationCfgOptions.findIndex(
        ({id}) => id == this.axisNameLocation
      );
      this.locationPos = pos >= 0 ? pos : this.defaultLocationIndex;
    },
    nextNamePosition() {
      this.locationPos =
        this.locationPos == this.nameLocationCfgOptions.length - 1
          ? 0
          : this.locationPos + 1;
      this.axisNameRotate =
        this.nameLocationCfgOptions[this.locationPos]?.rotate ?? 0;
      this.axisNameLocation = this.nameLocationCfgOptions[this.locationPos].id;
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped></style>
