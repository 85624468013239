<template>
  <TogglePanel
    title="controls"
    data-testid="toggle-panel"
    persistent="toggle_synoptic_controls"
  >
    <template #toolbar>
      <div style="margin: 5px 0 0 0">
        <span
          class="btn btn-xs"
          @click.stop.prevent="clampControls()"
          style="margin-right: 2px"
          v-if="hasOutOfWindowItems"
          :title="$t('fix_items_outside_the_visible_area')"
        >
          <i class="fa fa-crosshairs"></i>
        </span>
        <span
          class="btn btn-xs"
          @click.stop.prevent="toggleAll"
          style="margin-right: 2px"
          :title="
            `${$tc('all', 1)} ${$tc(isAllEnabled ? 'disabled' : 'enabled', 2)} `
          "
        >
          <i :class="isAllEnabled ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
        </span>
        <span
          class="btn btn-xs"
          @click.stop.prevent="removeAll"
          style="margin-right: 34px"
          ><i class="fa fa-trash"></i
        ></span>
      </div>
    </template>
    <div class="me">
      <draggable class="list-group" v-model="controls" v-if="controls.length">
        <div v-for="(item, ix) in controls" v-bind:key="ix">
          <div
            class="list-group-item item group-item"
            v-bind:class="isSelected(item.id) ? 'active' : ''"
            data-testid="control"
          >
            <div
              class="control-icon move handle"
              :style="`background-image:url('${itemIcon(item)}')`"
              :title="itemComponentName(item)"
              @click="onIconClick($event, item, false)"
              @dblclick="onIconClick($event, item, true)"
            ></div>
            <input
              @click="onTextClick($event, item, false)"
              @dblclick="onTextClick($event, item, true)"
              :value="item.name"
              :disabled="item.linked"
              :placeholder="item.title"
              @input="updateName(ix, $event.target.value)"
              @keydown.tab.exact.prevent.stop
              @keydown.ctrl.tab.exact.prevent.stop
              @keydown.shift.tab.exact.prevent.stop
              @keydown.esc.prevent.stop="clearSelection"
              data-testid="name"
            />

            <span class="item-buttons">
              <span
                class="btn btn-xs"
                v-bind:class="isSelected(item.id) ? '' : 'text-danger'"
                v-if="itemError(item)"
                :title="itemError(item)"
                @click="onIconClick($event, item, true)"
              >
                <i class="fa fa-exclamation-triangle"></i>
              </span>
              <span
                class="btn btn-xs"
                v-on:click.prevent.stop="duplicateControl(item.id)"
                data-testid="duplicate"
              >
                <i class="fa fa-copy"></i>
              </span>
              <span
                class="btn btn-xs"
                v-on:click.prevent.stop="toggleControl(item.id)"
                data-testid="toggle"
              >
                <i class="fa fa-eye" v-if="item.enabled"></i>
                <i class="fa fa-eye-slash" v-else></i>
              </span>
              <span
                class="btn btn-xs"
                v-on:click.prevent.stop="delControl(item.id)"
                data-testid="delete"
              >
                <i class="fa fa-trash"></i>
              </span>
            </span>
          </div>
        </div>
      </draggable>
      <div v-else class="empty-alert text-center">
        {{ $t("no_result_found") }}
      </div>
    </div>
  </TogglePanel>
</template>

<script>
import GalleryItems from "@/assets/dashboard/controls.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import draggable from "vuedraggable";
export default {
  name: "ControlSelectionPanel",
  components: {
    draggable,
    TogglePanel
  },
  data() {
    return {
      gallery_items: GalleryItems
    };
  },
  computed: {
    canvasSize() {
      return this.$store.getters["synoptic/canvasSize"];
    },
    controls: {
      get() {
        let lst = [];
        (this.$store.getters["synoptic/controls"] || []).forEach((item) =>
          lst.unshift(item)
        );
        return lst;
      },
      set(value) {
        let lst = [];
        (value || []).forEach((item) => lst.unshift(item));
        this.$store.dispatch("synoptic/setControls", lst);
      }
    },
    nEnabled() {
      return this.controls.filter(({enabled}) => enabled).length;
    },
    nControls() {
      return this.controls.length;
    },
    nDisabled() {
      return this.nControls - this.nEnabled;
    },
    isAllEnabled() {
      return this.nControls && !this.nDisabled;
    },
    hasOutOfWindowItems() {
      return this.controls.some((i) => this.isOutOfWindow(i));
    }
  },
  methods: {
    toggleAll() {
      let value = !this.isAllEnabled;
      let lst = this.$store.getters["synoptic/controls"] || [];
      lst.forEach((i) => (i.enabled = i.linked ? i.enabled : value));
      this.$store.dispatch("synoptic/setControls", lst);
    },
    removeAll() {
      let lst = (this.$store.getters["synoptic/controls"] || []).filter(
        (i) => i.linked
      );
      this.$utils
        .confirm(
          this,
          this.$tc("remove") +
            ": " +
            this.$tc("n_item_selected", this.nControls - lst.length)
        )
        .then((ok) => {
          if (!ok) return;
          this.$store.dispatch("synoptic/setControls", lst);
        });
    },
    isOutOfWindow(item) {
      let rect =
        (this.canvasSize && item?.synopticComponent?.clientRect) || null;
      return (
        !rect ||
        rect.top < 0 ||
        rect.left < 0 ||
        rect.left + rect.width > this.canvasSize.w ||
        rect.top + rect.height > this.canvasSize.h
      );
    },
    itemError(item) {
      let lst = [];
      "data_id" in item && !item.data_id && lst.push(this.$t("invalid_data"));
      this.isOutOfWindow(item) && lst.push(this.$t("out_of_visible_area"));
      return lst.join("\n");
    },
    itemTitle(item) {
      if (item.name) {
        return item.name;
      }
      if (item.title) {
        return item.title; // new items
      }
      // items present on stored json
      let ctrl = this.gallery_items.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          item.synopticComponent.componentName
      );
      return (
        (ctrl && ctrl.template.title) || item.synopticComponent.componentName
      );
    },
    itemIcon(item) {
      // items present on stored json
      let ctrl = this.gallery_items.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          item.synopticComponent.componentName
      );
      return (ctrl && ctrl.thumbnail) || "";
    },
    itemComponentName(item) {
      // items present on stored json
      let ctrl = this.gallery_items.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          item.synopticComponent.componentName
      );
      return (ctrl && ctrl?.template?.title) || "";
    },
    isSelected(controlId) {
      return (this.$store.getters["synoptic/selectedControls"] || []).some(
        ({id}) => id == controlId
      );
    },
    onIconClick($event, item, controlForm) {
      $event.stopPropagation();
      $event.preventDefault();
      if (item && item.linked) return;
      if (this.isOutOfWindow(item)) {
        this.clampControls(item);
        return;
      }
      this.$store.dispatch("synoptic/selectControl", item.id);
      if (controlForm || $event.ctrlKey) {
        this.$nextTick(() => {
          this.$root.$emit("synoptic:tab", "synoptic-component");
        });
      }
    },
    onTextClick($event, item, dblClick) {
      $event.stopPropagation();
      $event.preventDefault();
      if (item && item.linked) return;
      if ($event.ctrlKey) {
        this.toggleSelection(item.id);
      } else {
        this.clearSelectedControls();
        this.addToSelection(item.id);
        let el = $event.target;
        if (dblClick) {
          el.focus();
          setTimeout(() => {
            el.setSelectionRange(0, el.value.length);
          }, 0);
        }
      }
    },
    selectControl(item) {
      this.$store.dispatch("synoptic/selectControl", item.id);
    },
    clearSelection($event) {
      $event.target.blur();
      this.$store.dispatch("synoptic/clearSelection");
    },
    updateName(index, name) {
      let control = JSON.parse(JSON.stringify(this.controls[index]));
      control.name = name;
      this.$store.dispatch("synoptic/updateControl", {
        id: control.id,
        control
      });
    },
    delControl(id) {
      if (this.getControlById(id)?.linked) return;
      this.$store.dispatch("synoptic/delControl", id);
    },
    toggleControl(id) {
      if (this.getControlById(id)?.linked) return;
      this.$store.dispatch("synoptic/toggleControl", id);
    },
    duplicateControl(id) {
      if (this.getControlById(id)?.linked) return;
      this.$store.dispatch("synoptic/duplicateControl", id);
    },
    getControlById(ctrlId) {
      return (this.controls || []).find(({id}) => id == ctrlId);
    },
    addToSelection(controlId) {
      this.$store.dispatch("synoptic/addToSelection", controlId);
    },
    removeFromSelection(controlId) {
      this.$store.dispatch("synoptic/removeFromSelection", controlId);
    },
    toggleSelection(controlId) {
      // if (this.control.linked) return;
      if (this.isSelected(controlId)) {
        this.removeFromSelection(controlId);
      } else {
        this.addToSelection(controlId);
      }
    },
    clearSelectedControls() {
      this.$store.dispatch("synoptic/clearSelectedControls");
    },
    clampControls(item) {
      this.$root.$emit("synoptic-editor:clamp-controls", item ? [item] : null);
    }
  }
};
</script>

<style scoped>
.me {
  max-height: 400px;
  overflow: auto;
}

.group-item {
  padding: 0;
  cursor: pointer;
  min-height: 30px;
}

.list-group-item > .item-buttons {
  position: absolute;
  z-index: 9999;
  right: 0;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 3px;
}

.list-group-item > .item-buttons > .btn {
  opacity: 0.8;
}

.list-group-item > .item-buttons > .btn:hover {
  opacity: 1;
}

.skin-dark .list-group-item > .item-buttons > .btn,
.skin-dark .list-group-item > .item-buttons > .btn:hover {
  opacity: 1;
  color: #fff;
}

.control-icon {
  position: absolute;
  top: 4px;
  left: 2px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 0px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  z-index: 1;
  cursor: move;
}

.skin-dark .control-icon {
  filter: grayscale(0.9) brightness(200%);
}

.control-icon:hover {
  cursor: move;
}

input {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  border: none !important;
  padding: 0 60px 0 26px;
  color: gray;
  background-color: transparent;
  vertical-align: middle;
}

.skin-dark input {
  color: var(--skin-dark-light);
}

input:focus {
  border: none !important;
  outline-width: 0 !important;
  background-color: transparent;
}

.active > input {
  color: #ffffff;
}

.active > input::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
}

.active > input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.active > input::placeholder {
  color: #ffffff;
}

.skin-dark .list-group-item .btn:not(.text-danger) {
  color: var(--skin-dark-light);
}

.skin-dark .list-group-item .btn:hover,
.skin-dark .list-group-item .btn:focus {
  color: #fff;
}

.empty-alert {
  background-color: whitesmoke;
  padding: 10px 5px;
  border-radius: 5px;
  border: 1px solid rgb(228, 228, 228);
}

.skin-dark .empty-alert {
  background-color: var(--skin-dark-dark);
  border-color: var(--skin-dark-darker);
}

.invalid-control {
  color: #ff5722;
}

.skin-dark .list-group-item.active,
.skin-dark .list-group-item.active:focus,
.skin-dark .list-group-item.active:hover {
  background-color: var(--skin-dark-darker);
}
</style>
